import React, { useCallback, useRef, useState } from "react"
import JSONDep from "../../../json/Departamentos.json"
import JSONPro from "../../../json/Provincias.json"
import JSONDis from "../../../json/Distritos.json"
import { Formik, Field, ErrorMessage } from 'formik';
import SectionBackground from "../../molecules/SectionBackground/SectionBackground"
import Title from "../../atoms/Title/Title"
import { css } from "@emotion/react"
import FormStyle from "./FormStyle"
import Step from "../Step/Step"
import { navigate } from "gatsby";

export default function LibroForm({title}) {

  //ESTILOS

  const titleCss = css`
    font-size: calc(100vw * (calc(24 / var(--width_base))));
    font-weight: 400;
    margin-top: calc(100vw * (calc(20 / var(--width_base))));
    margin-bottom: calc(100vw * (calc(30 / var(--width_base))));
    padding-left: calc(100vw * (calc(13 / var(--width_base))));
    padding-right: calc(100vw * (calc(13 / var(--width_base))));
  `;

  const buttonCss = css`
    font-size: calc(100vw * (calc(16 / var(--width_base))));
    line-height: calc(100vw * (calc(16 / var(--width_base))));
    background-color: #eee !important;
    padding: calc(100vw * (calc(10 / var(--width_base)))) calc(100vw * (calc(15 / var(--width_base))));
    border: calc(100vw * (calc(1 / var(--width_base)))) solid #ddd;
    &:not(:last-of-type){
      margin-right: calc(100vw * (calc(20 / var(--width_base))));
    }
  `;

    const SectionCss = css`
      @media (max-width: 767px){
        padding-right: calc(100vw * (calc(7 / var(--width_base))));
        padding-left: calc(100vw * (calc(7 / var(--width_base))));
      }
    `;

  // LOGICA

  const [envio, setEnvio] = useState("")

  const [stepActive, setStepActive] = useState('datos')

  const [nomProyecto, setNomProyecto] = useState('')
  const [idDepartamento, setIdDepartamento] = useState('')
  const [nomDepartamento, setNomDepartamento] = useState('')
  const [nomProvincia, setNomProvincia] = useState('')
  const [idProvincia, setIdProvincia] = useState('')
  const [nomDistrito, setNomDistrito] = useState('')
  const [idDistrito, setIdDistrito] = useState('')
  const [distritos, setDistritos] = useState('')
  const [provincias, setProvincias] = useState([])
  const formikRef = useRef(null);


  const stepData = [
    {
      "id": 1,
      "text": "Datos del Reclamante"
    },
    {
      "id": 2,
      "text": "Identificación del Reclamo/Queja"
    },
    {
      "id": 3,
      "text": "Detalle del Reclamo/Queja y detalle"
    }
  ]


  const handleDep = useCallback((event) => {
    let valoresSelect = "";
    if(event.target.value !== ""){
      valoresSelect = event.target.value.split(",");
      setIdDepartamento(valoresSelect[0]);
      setNomDepartamento(valoresSelect[1]);
      const pro = JSONPro[0][valoresSelect[0]].map((ele,i)=>{
        return(
          <option key={i} value={`${ele.id_ubigeo},${ele.nombre_ubigeo}`}>{ele.nombre_ubigeo}</option>
        )
      })
      setProvincias(pro);
    }else{
      setProvincias("")
      setNomDepartamento("")
      setNomProvincia("")
      setDistritos("")
      setNomDistrito("")
    }
    formikRef.current.setFieldValue("idProvincia", "");
    formikRef.current?.handleChange(event);
  }, []);


  const handleProv = useCallback((event) => {
    let valoresSelect = "";
    if(event.target.value !== ""){
      valoresSelect = event.target.value.split(",");
      setIdProvincia(valoresSelect[0])
      setNomProvincia(valoresSelect[1])
      const dis = JSONDis[0][valoresSelect[0]].map((ele,i)=>{
        return(
          <option key={i} value={`${ele.id_ubigeo},${ele.nombre_ubigeo}`}>{ele.nombre_ubigeo}</option>
        )
      })
      setDistritos(dis)
    }else{
      setNomProvincia("")
      setNomDistrito("")
      setDistritos("")
    }
    formikRef.current.setFieldValue("idDistrito", "");
    formikRef.current?.handleChange(event);
  }, []);

  const handleDis = useCallback((event) => {
    let valoresSelect = "";
    if(event.target.value !== ""){
      valoresSelect = event.target.value.split(",");
      setIdDistrito(valoresSelect[0]);
      setNomDistrito(valoresSelect[1]);
    }else{
      setNomDistrito("")
    }
    formikRef.current?.handleChange(event);
  }, []);

  const handleProyecto = useCallback((event) => {
    let valoresSelect = "";
    if(event.target.value !== ""){
      valoresSelect = event.target.value.split("-");
      let nombreProyecto = valoresSelect[0].trim().replace(" ","").replace(" ","");
      setNomProyecto(nombreProyecto);
    }else{
      setNomProyecto("");
    }
    formikRef.current?.handleChange(event);
  }, []);

  return (
    <SectionBackground widthContent={1226} styleCss={SectionCss}>
      <Title typeEle="h1" text={title} styleCss={titleCss}/>
      <Formik
        innerRef={formikRef}
        initialValues={{
          Nombre: '',
          Apellido: '',
          TipoDocumento: '',
          NroDocumento: '',
          Direccion: '',
          idDepartamento: '',
          idProvincia: '',
          idDistrito: '',
          Telefono: '',
          Email: '',
          Proyecto: '',
          QuejaReclamo: '',
          Pedido: '',
          DetalleReclamo: '',
          Acepto_condiciones: '',
          Acepto_condiciones2: '',
          Acepto_condiciones3: '',
        }}
        validate={values => {
          const errors = {};

          if(stepActive === "datos"){
            if (!values.Nombre) errors.Nombre = 'Campo requerido';
            if (!values.Apellido) errors.Apellido = 'Campo requerido';
            if (!values.TipoDocumento) errors.TipoDocumento = 'Campo requerido';
            if (!values.NroDocumento) errors.NroDocumento = 'Campo requerido';
            if (!values.Direccion) errors.Direccion = 'Campo requerido';
            if (!values.idDepartamento) errors.idDepartamento = 'Campo requerido';
            if (!values.idProvincia) errors.idProvincia = 'Campo requerido';
            if (!values.idDistrito) errors.idDistrito = 'Campo requerido';
            if (!values.Telefono) errors.Telefono = 'Campo requerido';
            if (!values.Email) errors.Email = 'Campo requerido';
            else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.Email)) errors.Email = 'Correo Invalido';
            if (!values.Proyecto) errors.Proyecto = 'Campo requerido';
          }

          if(stepActive === "identificacion"){
            if (!values.QuejaReclamo) errors.QuejaReclamo = 'Campo requerido';
          }

          if(stepActive === "detalle"){
            if (!values.DetalleReclamo) errors.DetalleReclamo = 'Campo requerido';
            if (!values.Pedido) errors.Pedido = 'Campo requerido';
            if (!values.Acepto_condiciones) errors.Acepto_condiciones = 'Campo requerido';
            if (!values.Acepto_condiciones2) errors.Acepto_condiciones2 = 'Campo requerido';
            if (!values.Acepto_condiciones3) errors.Acepto_condiciones3 = 'Campo requerido';
          }


          return errors;
        }}
        onSubmit={(values, { setSubmitting,resetForm }) => {
          if(stepActive==="detalle"){
            try{
              fetch(`${process.env.GATSBY_PAGINA_COMPILADOR}/wp-json/codReclamo/v1/codReclamo4`, {
                method: 'GET',
                headers: {
                  "Content-Type": "application/json"
                },
              }).then(res => res.json())
                .then(json => {
                  let codReclamo = json;
                  let today = new Date();
                  let year = today.getFullYear();
                  let ceros = "";
                  if(codReclamo >=0 && codReclamo <=9) ceros = "00";
                  if(codReclamo >=10 && codReclamo <=99) ceros = "0";
                  let newcodigo =  ceros + codReclamo + "-" + year + "-" + nomProyecto;

                  const formData = new FormData();
                  formData.append("_wpcf7_unit_tag", "wpcf7-f6-o1")
                  formData.append("CodReclamo", newcodigo);
                  formData.append("Nombre", values.Nombre);
                  formData.append("Apellido", values.Apellido);
                  formData.append("TipoDocumento", values.TipoDocumento);
                  formData.append("NroDocumento", values.NroDocumento);
                  formData.append("Direccion", values.Direccion);
                  formData.append("Departamento", nomDepartamento);
                  formData.append("Provincia", nomProvincia);
                  formData.append("Distrito", nomDistrito);
                  formData.append("Telefono", values.Telefono);
                  formData.append("Email", values.Email);
                  formData.append("Proyecto", values.Proyecto);
                  formData.append("QuejaReclamo", values.QuejaReclamo);
                  formData.append("DetalleReclamo", values.DetalleReclamo);
                  formData.append("NombreProyecto", nomProyecto);
                  formData.append("Pedido", values.Pedido);

                  fetch(`${process.env.GATSBY_PAGINA_COMPILADOR}/wp-json/contact-form-7/v1/contact-forms/6/feedback`, {
                    method: 'POST',
                    body: formData
                  }).then(res => res.json())
                    .then(json => {
                      if(json.status === "mail_sent"){
                        setEnvio(true)
                          setSubmitting(false);
                          resetForm();
                          navigate('/gracias-libro-reclamaciones')
                        }
                        else{
                          setEnvio(false)
                        }
                        setStepActive('datos');
                    })
                })
            }catch (error) {
              console.error(error);
              setEnvio(false);
              setStepActive('datos');
            }
          }
          if(stepActive==="datos") setStepActive('identificacion'); setSubmitting(false);
          if(stepActive==="identificacion") setStepActive('detalle');setSubmitting(false);
        }}
      >
        {({ isSubmitting }) => (
          <FormStyle>

            <input type="hidden" value={idDepartamento} name="IdDepartamento" />
            <input type="hidden" value={nomDepartamento} name="nomDepartamento" />
            <input type="hidden" value={nomProvincia} name="nomProvincia" />
            <input type="hidden" value={idProvincia} name="idProvincia" />
            <input type="hidden" value={nomDistrito} name="nomDistrito" />
            <input type="hidden" value={idDistrito} name="idDistrito" />

            <Step navElements={stepData} navNumActives={1} active={stepActive==="datos"}>
              <div className="form-group grid-100">
                <Field className="form-control" type="text" name="Nombre" placeholder="*Nombres" />
                <ErrorMessage className="form-error" name="Nombre" component="div" />
              </div>
              <div className="form-group grid-100">
                <Field className="form-control" type="text" name="Apellido" placeholder="*Apellidos" />
                <ErrorMessage className="form-error" name="Apellido" component="div" />
              </div>
              <div className="form-group grid-50 mobile-grid-50 space-right">
                <Field name="TipoDocumento" as="select" className="form-control">
                  <option value="">*Tipo de documento</option>
                  <option value="DNI">DNI</option>
                  <option value="PASAPORTE">PASAPORTE</option>
                  <option value="RUC">RUC</option>
                  <option value="CARNET EXTRANJERIA">Carnet Extranjería</option>
                </Field>
                <ErrorMessage className="form-error" name="TipoDocumento" component="div" />
              </div>
              <div className="form-group grid-50 mobile-grid-50 space-left">
                <Field className="form-control" type="text" name="NroDocumento" placeholder="*Nro de documento" />
                <ErrorMessage className="form-error" name="NroDocumento" component="div" />
              </div>
              <div className="form-group grid-100">
                <Field name="Direccion" as="textarea" className="form-control" placeholder="*Dirección">
                </Field>
                <ErrorMessage className="form-error" name="Direccion" component="div" />
              </div>
              <div className="form-group grid-33 mobile-grid-33 space-right">
                <Field name="idDepartamento" as="select" className="form-control" onChange={handleDep}>
                  <option value="">Seleccionar</option>
                  {
                    JSONDep && JSONDep.map((dep,i)=>(
                      <option key={i} value={`${dep.id_ubigeo},${dep.nombre_ubigeo}`}>{dep.nombre_ubigeo}</option>
                    ))
                  }
                </Field>
                <ErrorMessage className="form-error" name="idDepartamento" component="div" />
              </div>
              <div className="form-group grid-33 mobile-grid-33 space-right space-left">
                <Field name="idProvincia" as="select" className="form-control" onChange={handleProv}>
                  <option value="">Seleccionar</option>
                  {provincias}
                </Field>
                <ErrorMessage className="form-error" name="idProvincia" component="div" />
              </div>
              <div className="form-group grid-33 mobile-grid-33 space-left">
                <Field name="idDistrito" as="select" className="form-control" onChange={handleDis}>
                  <option value="">Seleccionar</option>
                  {distritos}
                </Field>
                <ErrorMessage className="form-error" name="idDistrito" component="div" />
              </div>
              <div className="form-group grid-50  mobile-grid-50 space-right">
                <Field className="form-control" type="text" name="Telefono" placeholder="*Teléfono fijo / Movil" />
                <ErrorMessage className="form-error" name="Telefono" component="div" />
              </div>
              <div className="form-group grid-50 mobile-grid-50 space-left">
                <Field className="form-control" type="text" name="Email" placeholder="*Correo Electrónico" />
                <ErrorMessage className="form-error" name="Email" component="div" />
              </div>
              <div className="form-group grid-100">
                <Field name="Proyecto" as="select" className="form-control" onChange={handleProyecto}>
                  <option value="">*Seleccionar proyecto</option>
                  <option value="Basadre - HEXAGRAMA 1 - RUC  20511107734">Basadre - HEXAGRAMA 1 - RUC 20511107734</option>
                  <option value="Nogales - HEXAGRAMA 1 - RUC  20511107734">Nogales - HEXAGRAMA 1 - RUC 20511107734</option>
                  <option value="Vanderghen - HEXAGRAMA 1 - RUC  20511107734  ">Vanderghen - HEXAGRAMA 1 - RUC 20511107734 </option>
                  <option value="Machaypuito - INVERSIONES GP3 - RUC  20604500797">Machaypuito - INVERSIONES GP3 - RUC 20604500797</option>
                  <option value="Pasaje Laureles - INVERSIONES GP5 - RUC  20605493042">Pasaje Laureles - INVERSIONES GP5 - RUC 20605493042</option>
                  <option value="Libertad - INVERSIONES GP7 - RUC  20605741518">Libertad - INVERSIONES GP7 - RUC 20605741518</option>
                  <option value="Sáenz Peña - HEXAGRAMA 17 - RUC  20604207844">Sáenz Peña - HEXAGRAMA 17 - RUC 20604207844</option>
                  <option value="Ugarte y Moscoso - PROMO INMUEBLES PERU - RUC  20552986025">Ugarte y Moscoso - PROMO INMUEBLES PERU - RUC 20552986025</option>
                  <option value="Toribio Polo Inversiones GP4 SAC - 20604774447">Toribio Polo Inversiones GP4 SAC - 20604774447</option>
                  <option value="Pasaje los Pinos - Rentando Paredes Sac - 20605493042">Pasaje los Pinos - Rentando Paredes Sac - 20605493042</option>
                  <option value="Dos de Mayo - Inversiones GP5 SAC - 20604072787">Dos de Mayo - Inversiones GP5 SAC - 20604072787</option>
                </Field>
                <ErrorMessage className="form-error" name="Proyecto" component="div" />
              </div>
              <div className="form-group grid-100">
                <button type="submit" css={buttonCss}>Siguiente</button>
              </div>
            </Step>
            <Step navElements={stepData} navNumActives={2} active={stepActive==="identificacion"}>
              <div className="form-group grid-100">
                <label htmlFor="Queja" className="form-label">
                  <Field className="form-control" type="radio" name="QuejaReclamo" id="Queja" value="Queja: Disconformidad no relacionada a los productos o servicios; o, malestar o descontento respecto a la atención al público."/>
                  &nbsp;&nbsp;Queja: Disconformidad no relacionada a los productos o servicios; o, malestar o descontento respecto a la atención al público.
                </label>
                <label htmlFor="Reclamo" className="form-label">
                  <Field className="form-control" type="radio" name="QuejaReclamo" id="Reclamo" value="Reclamo: Disconformidad relacionada a los productos y servicios."/>
                  &nbsp;&nbsp;Reclamo: Disconformidad relacionada a los productos y servicios.
                </label>
                <ErrorMessage className="form-error" name="QuejaReclamo" component="div" />
              </div>
              {/*<div className="form-group form-label grid-100">* El proveedor deberá dar respuesta al reclamo en un plazo no mayor a quince (15) días calendario, pudiendo ampliar el plazo hasta por treinta (30) días más, previa comunicación al consumidor.</div>*/}
              <div className="form-group form-label grid-100">* El proveedor deberá dar respuesta al reclamo en un plazo no mayor a quince (15) días calendario.</div>
              <div className="form-group grid-100">
                <button type="button" css={buttonCss} onClick={() => setStepActive('datos')}>Anterior</button>
                <button type="submit" css={buttonCss}>Siguiente</button>
              </div>
            </Step>
            <Step navElements={stepData} navNumActives={3} active={stepActive==="detalle"}>
              <div className="form-group grid-100">
                <Field className="form-control" as="textarea" name="DetalleReclamo" placeholder="Detalle del reclamo o queja" />
                <ErrorMessage className="form-error" name="DetalleReclamo" component="div" />
              </div>
              <div className="form-group grid-100">
                <Field className="form-control" as="textarea" name="Pedido" placeholder="Pedido del reclamo o queja" />
                <ErrorMessage className="form-error" name="Pedido" component="div" />
              </div>
              <div className="form-group grid-100">
                <label className="form-acept form-label" htmlFor="Acepto_condiciones">
                  <Field className="" type="checkbox" name="Acepto_condiciones" id="Acepto_condiciones" />&nbsp;&nbsp;Al enviar este reclamo / queja usted está conforme con los datos consignados.</label>
                <ErrorMessage className="form-error mov-mb-13" name="Acepto_condiciones" component="div" />
              </div>
              <div className="form-group grid-100">
                <label className="form-acept form-label" htmlFor="Acepto_condiciones2">
                  <Field className="" type="checkbox" name="Acepto_condiciones2" id="Acepto_condiciones2" />&nbsp;&nbsp;He leído y acepto la  {" "}
                  <a
                    href="/politica-de-privacidad/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Política de privacidad
                  </a>
                </label>
                <ErrorMessage className="form-error mov-mb-13" name="Acepto_condiciones2" component="div" />
              </div>
              <div className="form-group grid-100">
                <label className="form-acept form-label" htmlFor="Acepto_condiciones3">
                  <Field className="" type="checkbox" name="Acepto_condiciones3" id="Acepto_condiciones3" />&nbsp;&nbsp;He leído y acepto la transferencia de mis datos a las empresas del grupo empresarial Armando Paredes.</label>
                <ErrorMessage className="form-error mov-mb-13" name="Acepto_condiciones3" component="div" />
              </div>
              <div className="form-group grid-100">
                <button type="submit" disabled={isSubmitting} css={buttonCss}>Registrar</button>
              </div>
            </Step>
            <div className="grid-100">
              { (envio === false && envio !== "") && <div className="">Error en el envio</div>}
              { (envio === true && envio !== "") && <div className="">Correo enviado</div>}
            </div>
          </FormStyle>
        )}
      </Formik>
    </SectionBackground>
  );

}