import React, { useState } from "react"
import { Formik, Form, Field, ErrorMessage } from "formik"
import { css } from "@emotion/react"

export default function PilotoForm() {
  const formCss = css`
    padding-right: calc(100vw * (calc(60 / var(--width_base))));
    padding-left: calc(100vw * (calc(60 / var(--width_base))));
    & .form-group {
      margin-bottom: calc(100vw * (calc(8 / var(--width_base))));
    }

    & .form-error {
      margin-bottom: calc(100vw * (calc(8 / var(--width_base))));
    }

    & .form-control {
      background-color: transparent;
      padding: calc(100vw * (calc(6 / var(--width_base))))
        calc(100vw * (calc(10 / var(--width_base))));
      font-size: calc(100vw * (calc(28 / var(--width_base))));
      line-height: calc(100vw * (calc(34 / var(--width_base))));
      color: #3f3d1e;
      border: none;
      border-bottom: 1px solid #3f3d1e;
      text-align: center;
      margin-bottom: calc(100vw * (calc(10 / var(--width_base))));
    }
    @media (max-width: 767px) {
      padding-right: calc(100vw * (calc(7 / var(--width_base))));
      padding-left: calc(100vw * (calc(7 / var(--width_base))));
      & .form-control {
        font-size: calc(100vw * (calc(20 / var(--width_base))));
        line-height: calc(100vw * (calc(24 / var(--width_base))));
      }
    }
  `

  const formAceptCss = css`
    color: #3f3d1e;
    font-size: calc(100vw * (calc(17 / var(--width_base))));
    line-height: calc(100vw * (calc(30 / var(--width_base))));
    margin-top: calc(100vw * (calc(15 / var(--width_base))));
    display: inline-block;
    font-family: Gopher-Regular, sans-serif;
    @media (max-width: 767px) {
      margin-bottom: calc(100vw * (calc(15 / var(--width_base))));
      margin-left: calc(100vw * (calc(22 / var(--width_base))));
    }
    & input {
      margin-right: calc(100vw * (calc(10 / var(--width_base))));
      @media (max-width: 767px) {
        margin-left: calc(100vw * (calc(-22 / var(--width_base))));
      }
    }
  `

  const submitCss = css`
    color: #3f3e3c;
    font-size: calc(100vw * (calc(26 / var(--width_base))));
    padding: calc(100vw * (calc(15 / var(--width_base))))
      calc(100vw * (calc(50 / var(--width_base))));
    font-family: "Source Sans Pro", sans-serif;
    background-color: transparent !important;
    margin-left: auto;
    margin-top: calc(100vw * (calc(30 / var(--width_base))));
    text-transform: uppercase;
    border: 1px solid #c19f7a !important;
  `

  const linkCss = css`
      color: initial;
    `;

  const [envio, setEnvio] = useState("")

  // let utm_campaign = getUrlParameter("utm_campaign")
  //   ? getUrlParameter("utm_campaign")
  //   : ""
  // let utm_content = getUrlParameter("utm_content")
  //   ? getUrlParameter("utm_content")
  //   : ""
  // let utm_medium = getUrlParameter("utm_medium")
  //   ? getUrlParameter("utm_medium")
  //   : ""
  // let utm_source = getUrlParameter("utm_source")
  //   ? getUrlParameter("utm_source")
  //   : ""
  // let utm_term = getUrlParameter("utm_term") ? getUrlParameter("utm_term") : ""

  return (
    <section>
      <Formik
        initialValues={{
          Nombre: "",
          Apellido: "",
          Email: "",
          Telefono: "",
          Cafe: "",
          Acepto_condiciones: "",
          Acepto_condiciones2: "",
        }}
        validate={values => {
          const errors = {}

          if (!values.Nombre) {
            errors.Nombre = "Campo requerido"
          }

          if (!values.Apellido) {
            errors.Apellido = "Campo requerido"
          }
          if (!values.Cafe) {
            errors.Cafe = "Campo requerido"
          }

          if (!values.Telefono) {
            errors.Telefono = "Campo requerido"
          }
          if (!values.Acepto_condiciones) {
            errors.Acepto_condiciones = "Campo requerido"
          }
          if (!values.Acepto_condiciones2) {
            errors.Acepto_condiciones2 = "Campo requerido"
          }
          if (!values.Email) {
            errors.Email = "Campo requerido"
          } else if (
            !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.Email)
          ) {
            errors.Email = "Correo Invalido"
          }
          return errors
        }}
        onSubmit={(values, { setSubmitting, resetForm }) => {
          const dataJSON = sessionStorage.getItem("obj_data")
          const data = JSON.parse(dataJSON)

          try {
            const jsonData = {
              email: values.Email,
              fname: values.Nombre,
              lname: values.Apellido,
              phone: values.Telefono,
              project_id: 4,
              source_id: data?.source_id,
              observation: `Cafe: ${values.Cafe}, fbc: ${data?.fbc}, fbp: ${data?.fbp}, ip_address: ${data?.ip_address}`,
              interest_type_id: data?.interese_type_id,
              input_channel_id: data?.input_channel_id,
              utm_source: data?.utm_source,
              utm_medium: data?.utm_medium,
              utm_campaign: data?.utm_campaign,
              utm_content: data?.utm_content,
              utm_term: data?.gclid,
              client_ip_address: data?.ip_address,
              fbc: data?.fbc,
              fbp: data?.fbp,
            }
            fetch(`${process.env.GATSBY_API_APROS_CREAR_CLIENTES}`, {
              method: "POST",
              headers: {
                // Authorization: `Bearer ${process.env.GATSBY_API_KEY}`,
                "Content-Type": "application/json",
              },
              body: JSON.stringify(jsonData),
            })
              .then(res => res.json())
              .then(json => {
                json = JSON.parse(json)
                if (json?.data?.id) {
                  setEnvio(true)
                  setSubmitting(false)
                  resetForm()

                  fetch(`${process.env.GATSBY_WEBHOOK}`, {
                    method: "POST",
                    headers: {
                      "Content-Type": "application/json",
                    },
                    body: JSON.stringify(jsonData),
                  })
                    .then(response => response.json())
                    .then(result => console.log(result?.data?.status))
                    .catch(error => console.error(error))
                } else {
                  setEnvio(false)
                }
              })
          } catch (error) {
            console.error(error)
            setEnvio(false)
          }
        }}
      >
        {({ isSubmitting }) => (
          <Form className="grid-100" css={formCss}>
            <div className="form-group">
              <Field
                className="form-control"
                type="text"
                name="Nombre"
                placeholder="NOMBRE"
              />
              <ErrorMessage
                className="form-error"
                name="Nombre"
                component="div"
              />
            </div>
            <div className="form-group">
              <Field
                className="form-control"
                type="text"
                name="Apellido"
                placeholder="APELLIDOS"
              />
              <ErrorMessage
                className="form-error"
                name="Apellido"
                component="div"
              />
            </div>
            <div className="form-group">
              <Field
                className="form-control"
                type="text"
                name="Email"
                placeholder="MAIL"
              />
              <ErrorMessage
                className="form-error"
                name="Email"
                component="div"
              />
            </div>
            <div className="form-group">
              <Field
                className="form-control"
                type="tel"
                name="Telefono"
                placeholder="TELÉFONO*"
              />
              <ErrorMessage
                className="form-error"
                name="Telefono"
                component="div"
              />
            </div>
            <div className="form-group">
              <Field name="Cafe" as="select" className="form-control">
                <option value="">¿CON QUE CAFE TE ESPERAMOS?</option>
                <option value="Expreso doble">Expreso doble</option>
                <option value="Americano">Americano</option>
                <option value="Café pasado">Café pasado</option>
              </Field>
              <ErrorMessage
                className="form-error"
                name="Cafe"
                component="div"
              />
            </div>
            <div className="form-group">
              <label
                className="form-acept"
                htmlFor="Acepto_condiciones"
                css={formAceptCss}
              >
                <Field
                  className=""
                  type="checkbox"
                  name="Acepto_condiciones"
                  id="Acepto_condiciones"
                />
                He leído y acepto la {' '}  <a href="/politica-de-privacidad/" css={linkCss} target="_blank" rel="noreferrer">Política de privacidad</a>.
              </label>
              <ErrorMessage
                className="form-error mov-mb-13"
                name="Acepto_condiciones"
                component="div"
              />
              <label
                className="form-acept"
                htmlFor="Acepto_condiciones2"
                css={formAceptCss}
              >
                <Field
                  className=""
                  type="checkbox"
                  name="Acepto_condiciones2"
                  id="Acepto_condiciones2"
                />
                He leído y acepto la transferencia de mis datos a las empresas del grupo empresarial Armando Paredes. 
              </label>
              <ErrorMessage
                className="form-error mov-mb-13"
                name="Acepto_condiciones2"
                component="div"
              />
            </div>
            <div className="form-group">
              <button
                css={submitCss}
                className=""
                type="submit"
                disabled={isSubmitting}
              >
                Agendar
              </button>
            </div>
            {envio === false && envio !== "" && (
              <div className="">Error en el envio</div>
            )}
            {envio === true && envio !== "" && (
              <div className="">Correo enviado</div>
            )}
          </Form>
        )}
      </Formik>
    </section>
  )
}
