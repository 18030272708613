import React, {useState} from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { css } from "@emotion/react"
import Paragraph from '../../atoms/Paragraph/Paragraph';

export default function ProgramaForm({bgColorInput,bgColorSubmit, colorFondoInput}) {

  const [envio, setEnvio] = useState("");

  const apellidosCss = css`
    padding-left: calc(100vw * (calc(8 / var(--width_base))));
    padding-right: 0 !important;
    @media (max-width: 767px){
      padding-left: calc(100vw * (calc(0 / var(--width_base))));

    }
  `;

  const formCss = css`
    & .form-group{
      margin-bottom: calc(100vw * (calc(8 / var(--width_base))));
    }

    & .form-error{
      margin-bottom: calc(100vw * (calc(8 / var(--width_base))));
    }

    & .form-control{
      background-color: #F1EFE5;
      padding: calc(100vw * (calc(6 / var(--width_base)))) calc(100vw * (calc(10 / var(--width_base))));
      border: calc(100vw * (calc(1 / var(--width_base)))) solid #ccc;
      font-size: calc(100vw * (calc(16 / var(--width_base))));
      line-height: calc(100vw * (calc(16 / var(--width_base))));
      font-style: italic;
      border-radius: calc(100vw * (calc(10 / var(--width_base))));
    }
  `;

  const submitCss = css`
    background-color: ${bgColorSubmit} !important;
    padding: calc(100vw * (calc(16 / var(--width_base)))) calc(100vw * (calc(40 / var(--width_base)))) calc(100vw * (calc(8 / var(--width_base)))) calc(100vw * (calc(40 / var(--width_base))));
    font-size: calc(100vw * (calc(12 / var(--width_base))));
    line-height: calc(100vw * (calc(12 / var(--width_base))));
    width: 100%;
    border-radius: calc(100vw * (calc(10 / var(--width_base))));
    text-transform: uppercase;
    font-family: "Gotham", sans-serif;
    font-weight: 500;
    display: inline-block;
    @media (max-width: 767px){
      padding: calc(100vw * (calc(16 / var(--width_base)))) calc(100vw * (calc(40 / var(--width_base)))) calc(100vw * (calc(16 / var(--width_base)))) calc(100vw * (calc(40 / var(--width_base))));
    }
  `;

  const formAceptCss = css`
    color: white;
    font-size: calc(100vw * (calc(16 / var(--width_base))));
    line-height: calc(100vw * (calc(16 / var(--width_base))));
    margin-top: calc(100vw * (calc(3 / var(--width_base))));
    display: inline-block;
    font-family: "Gotham Thim", sans-serif;
    @media (max-width: 767px) {
      padding-left: calc(100vw * (calc(20 / var(--width_base))));
      padding-right: calc(100vw * (calc(20 / var(--width_base))));
    }

    & input {
      @media (max-width: 767px) {
        margin-left: calc(100vw * (calc(-22 / var(--width_base))));
      }
    }
  `

  const linkCss = css`
    color: white;
  `

  const titleCss = css`
    font-size: calc(100vw * (calc(17 / var(--width_base))));
    color: ${colorFondoInput};
  `;

  const titleCss1 = css`
    margin-bottom: calc(100vw * (calc(7 / var(--width_base))));
    ${titleCss}
  `;

  const titleCss2 = css`
    margin-top: calc(100vw * (calc(15 / var(--width_base))));
    margin-bottom: calc(100vw * (calc(15 / var(--width_base))));
    ${titleCss}
  `;

  return (
    <section>
      <Formik
        initialValues={{
          NombreSocio: '',
          ApellidoSocio: '',
          CelularSocio: '',
          EmailSocio: '',
          DNISocio: '',
          Nombre: '',
          Apellido: '',
          Email: '',
          Celular: '',
          Acepto_condiciones: '',
          Me_interesa: '',
        }}
        validate={values => {
          const errors = {};

          if (!values.NombreSocio) errors.NombreSocio = 'Campo requerido';

          if (!values.ApellidoSocio) errors.ApellidoSocio = 'Campo requerido';

          if (!values.CelularSocio) errors.CelularSocio = 'Campo requerido';

          if (!values.DNISocio) errors.DNISocio = 'Campo requerido';

          if (!values.Acepto_condiciones) errors.Acepto_condiciones = 'Campo requerido';
          
          if (!values.Me_interesa) errors.Me_interesa = 'Campo requerido';

          if (!values.EmailSocio) errors.EmailSocio = 'Campo requerido';
          else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.EmailSocio)
          ) errors.EmailSocio = 'Correo Invalido';

          return errors;
        }}
        onSubmit={(values, { setSubmitting,resetForm }) => {
          try{

            const formData = new FormData();
            formData.append("_wpcf7_unit_tag", "wpcf7-f11093-o1")
            formData.append('NombreSocio', values.NombreSocio);
            formData.append('ApellidoSocio', values.ApellidoSocio);
            formData.append('CelularSocio', values.CelularSocio);
            formData.append('EmailSocio', values.EmailSocio);
            formData.append('DNISocio', values.DNISocio);
            formData.append('Nombre', values.Nombre);
            formData.append('Apellido', values.Apellido);
            formData.append('Celular', values.Celular);
            formData.append('Email', values.Email);

            fetch(`${process.env.GATSBY_PAGINA_COMPILADOR}/wp-json/contact-form-7/v1/contact-forms/11093/feedback`, {
              method: 'POST',
              body: formData
            })
              .then(res => res.json())
              .then(json => {
                if(json.status === "mail_sent"){
                  setEnvio(true)
                  setSubmitting(false);
                  resetForm();
                }
                else{
                  setEnvio(false)
                }
              })
          }catch (error) {
            console.error(error);
            setEnvio(false)
          }
        }}
      >
        {({ isSubmitting }) => (
          <Form className="grid-100 grid-x-0" css={formCss}>
            <div className="grid-100 form-group grid-x-0">
              <Paragraph text="Inscríbete en el Plan de Referidos de Armando Paredes.<br>Ingresa tus datos:" styleCss={titleCss1}/>
            </div>
            <div className="grid-60 form-group grid-x-0">
              <Field className="form-control" type="text" name="NombreSocio" placeholder="Nombre*" />
              <ErrorMessage className="form-error" name="NombreSocio" component="div" />
            </div>
            <div className="grid-40 form-group" css={apellidosCss}>
              <Field className="form-control" type="text" name="ApellidoSocio" placeholder="Apellido*"/>
              <ErrorMessage className="form-error" name="ApellidoSocio" component="div" />
            </div>
            <div className="grid-100 form-group grid-x-0">
              <Field className="form-control" type="tel" name="CelularSocio" placeholder="Celular*" />
              <ErrorMessage className="form-error" name="CelularSocio" component="div" />
            </div>
            <div className="grid-100 form-group grid-x-0">
              <Field className="form-control" type="text" name="EmailSocio" placeholder="Correo*" />
              <ErrorMessage className="form-error" name="EmailSocio" component="div" />
            </div>
            <div className="grid-100 form-group grid-x-0">
              <Field className="form-control" type="text" name="DNISocio" placeholder="DNI*" />
              <ErrorMessage className="form-error" name="DNISocio" component="div" />
            </div>
            <div className="grid-100 form-group grid-x-0">
              <Paragraph text="Ingresa los datos de tu referido:" styleCss={titleCss2}/>
            </div>
            <div className="grid-60 form-group grid-x-0">
              <Field className="form-control" type="text" name="Nombre" placeholder="Nombre" />
              <ErrorMessage className="form-error" name="Nombre" component="div" />
            </div>
            <div className="grid-40 form-group" css={apellidosCss}>
              <Field className="form-control" type="text" name="Apellido" placeholder="Apellido"/>
              <ErrorMessage className="form-error" name="Apellido" component="div" />
            </div>
            <div className="grid-100 form-group grid-x-0">
              <Field className="form-control" type="tel" name="Celular" placeholder="Celular" />
              <ErrorMessage className="form-error" name="Celular" component="div" />
            </div>
            <div className="grid-100 form-group grid-x-0">
              <Field className="form-control" type="text" name="Email" placeholder="Correo" />
              <ErrorMessage className="form-error" name="Email" component="div" />
            </div>
            <div className="grid-100 form-group grid-x-0">
              <label
                className="form-acept"
                htmlFor="Acepto_condiciones"
                css={formAceptCss}
              >
                <Field
                  className="accept-box"
                  type="checkbox"
                  name="Acepto_condiciones"
                  id="Acepto_condiciones"
                />
                &nbsp;&nbsp;&nbsp;He leído y acepto {" "}
                <a
                  href="/politica-de-privacidad/"
                  css={linkCss}
                  target="_blank"
                  rel="noreferrer"
                >
                  la Política de privacidad
                </a>.
              </label>
              <ErrorMessage
                className="form-error mov-mb-13"
                name="Acepto_condiciones"
                component="div"
              />
            </div>
            <div className="grid-100 form-group grid-x-0">
              <label
                className="form-acept"
                htmlFor="Me_interesa"
                css={formAceptCss}
              >
                <Field
                  className="accept-box"
                  type="checkbox"
                  name="Me_interesa"
                  id="Me_interesa"
                />
                &nbsp;&nbsp;&nbsp;He leído y acepto la transferencia de mis datos a las empresas del grupo empresarial Armando Paredes.
              </label>
              <ErrorMessage
                className="form-error mov-mb-13"
                name="Me_interesa"
                component="div"
              />
            </div>
            <div className="grid-100 grid-x-0">
              <button className="" type="submit" disabled={isSubmitting} css={submitCss}>Enviar</button>
            </div>
            { (envio === false && envio !== "") && <div className="">Error en el envio</div>}
            { (envio === true && envio !== "") && <div css={css`color: white;margin-top: calc(100vw * (calc(10 / var(--width_base))))`}>Recibimos tus datos, pronto te contactaremos.</div>}
          </Form>
        )}
      </Formik>
    </section>
  );

}
