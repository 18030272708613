import React from 'react';
import { useStaticQuery, graphql, Link } from "gatsby";
import Title from "../../atoms/Title/Title";
import TextWhatsapp from "../../molecules/TextWhatsapp/TextWhatsapp";
import { css } from "@emotion/react";
import SectionBackground from "../../molecules/SectionBackground/SectionBackground"
import List from "../../molecules/List/List"
import Redes from "../Redes/Redes"
import Image from "../../atoms/Image/Image";

export default function FooterGeneral() {

	const data = useStaticQuery(graphql`
    {
      wordpress {
        page(id: "896", idType: DATABASE_ID) {
          title
          template {
            ... on Wordpress_Template_Opciones {
              opciones {
                titulo1Footer
                titulo2Footer
                correoFooter
                direccionFooter
                infoFooter
                linkFacebookFooter
                linkInstagramFooter
                linkLinkedinFooter
                linkSpotifyFooter
                linkWhatsappHeader
				imagenLibro {
                  ...imagen
                }
              }
            }
          }
        }
        menuItems(where: {location: MENU_FOOTER}) {
          nodes {
            id
            label
            url
            title
            target
            childItems(before: "") {
              edges {
                node {
                  id
                  label
                  url
                  title
                  target
                }
              }
            }
          }
        }
        generalSettings {
          url
        }
      }
    }
  `)

	console.log(data)
	const template = data?.wordpress?.page?.template?.opciones;
	const menuItems = data?.wordpress?.menuItems?.nodes;
	const wordPressUrl = data?.wordpress?.generalSettings?.url;

	const sectionCss = css`
    padding-top: calc(100vw * (calc(49 / var(--width_base))));
    padding-bottom: calc(100vw * (calc(45 / var(--width_base))));
  `;

	const footerContentCss = css`
    display: flex;
    @media (max-width: 767px){
     flex-direction: column; 
    }
  `;

	const footerColumnCss = css`
    &:first-of-type{
      width: calc(100vw * (calc(310 / var(--width_base))));
    }
    &:first-of-type + div{
      width: calc(100vw * (calc(570 / var(--width_base))));
    }

    &:first-of-type, &:first-of-type + div{
      @media (max-width: 767px){
        width: 100%;
      }
    }

    &:last-of-type{
      flex-grow: 1;
      display: flex;
      align-items: flex-start;
      justify-content: flex-start;
	  flex-direction: column;

      @media (max-width: 767px){
        justify-content: center;
      }
    }

    @media (max-width: 767px){
      padding-left: calc(100vw * (calc(40 / var(--width_base))));
      padding-right: calc(100vw * (calc(40 / var(--width_base))));
      margin-bottom: calc(100vw * (calc(40 / var(--width_base))));
    }
  `;

	const titleCss = css`
    font-size: calc(100vw * (calc(17 / var(--width_base))));
    line-height: calc(100vw * (calc(18 / var(--width_base))));
    margin-bottom: calc(100vw * (calc(10 / var(--width_base))));
    font-family: "Source Sans Pro", sans-serif;
    font-weight: bold;
  `;

	const footerRedesCss = css`
    display: flex;
    
    & .menu__item:not(:last-of-type){
      margin-right: calc(100vw * (calc(20 / var(--width_base))));
    }
    & .menu__link{
      width: calc(100vw * (calc(50 / var(--width_base))));
      height: calc(100vw * (calc(50 / var(--width_base))));
      display: inline-flex;
      flex-shrink: 0;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      font-size: calc(100vw * (calc(20 / var(--width_base))));
      overflow: hidden;
      position: relative;
      color: #212121;
      border: calc(100vw * (calc(2 / var(--width_base)))) solid #212121;
      transition: all 0.2s linear 0.2s;
      
      & i{
        position: relative;
        z-index: 3;
        transition: all 0.2s linear 0s;
      }
      
      &:hover i{
        transform: scale(1.15);
        text-shadow: 4px 0px 3px rgba(33, 33, 33, 0.3);
        transition: all 0.2s linear 0.2s;
        color: black;
      }
      
      &:hover{
        transition: all 0.2s linear 0s;
        border-color: rgba(33, 33, 33, 0);
      }
    }
    
    
  `;

	const menuListCss = css`
    .menu__item{
      padding-top: calc(100vw * (calc(6 / var(--width_base))));
      padding-bottom: calc(100vw * (calc(6 / var(--width_base))));
      @media (max-width: 767px){
        padding-top: calc(100vw * (calc(7 / var(--width_base))));
        padding-bottom: calc(100vw * (calc(7 / var(--width_base))));
      }
    }

    & .menu__item, & .menu__link{
      font-size:  calc(100vw * (calc(14 / var(--width_base))));
      line-height: calc(100vw * (calc(18 / var(--width_base))));
    }
  `;
	const libroCss = css`
	  &.gatsby-image-wrapper-constrained img, &{
		width: calc(100vw * (calc(80 / var(--width_base))));
		object-fit: contain;
		@media (max-width: 767px){
		  width: calc(100vw * (calc(90 / var(--width_base))));
		}
	  }
	`;
	const libroContainerCss = css`
	display:block;
  margin-top:calc(100vw * (calc(20 / var(--width_base))));
	@media (max-width: 767px){
		display:none;
      margin-top:0;
		}
	`;
	const libroMovilContainerCss = css`
	display:none;
	@media (max-width: 767px){
		display:block;
		}
	`;

	let correoTexto = template?.correoFooter.replace(/<[^>]*>?/g, '');

	const linkCorreo = `<a className="menu__link" href="mailto:${correoTexto}">${correoTexto}</a>`;

	return (
		<>
			<TextWhatsapp icon={false} type="imagen" />
			<SectionBackground styleCss={sectionCss} widthContent={1200} styleCssContent={footerContentCss}>
				<div css={footerColumnCss}>
					<Title typeEle="h3" text={template?.titulo1Footer} styleCss={titleCss} />
					<List menuItems={menuItems} wordPressUrl={wordPressUrl} styleCss={menuListCss} />
					<Link css={libroMovilContainerCss} to="/libro-de-reclamaciones">
						{
							template?.imagenLibro && <Image styleCss={libroCss} imagen={template?.imagenLibro} objectFit="contain" />
						}
					</Link>
				</div>
				<div css={footerColumnCss}>
					<Title typeEle="h3" text={template?.titulo2Footer} styleCss={titleCss} />
					<ul css={menuListCss}>
						<li className="menu__item"><TextWhatsapp icon={false} /></li>
						<li className="menu__item" dangerouslySetInnerHTML={{ __html: linkCorreo }} />
						<li className="menu__item">{template?.direccionFooter}</li>
						<li className="menu__item">{template?.infoFooter}</li>
					</ul>
				</div>
				<div css={footerColumnCss}>
					<Redes
						facebook={template?.linkFacebookFooter}
						instagram={template?.linkInstagramFooter}
						linkedin={template?.linkLinkedinFooter}
						spotify={template?.linkSpotifyFooter}
						styleCss={footerRedesCss}
					/>
					<Link css={libroContainerCss} to="/libro-de-reclamaciones">
						{
							template?.imagenLibro && <Image styleCss={libroCss} imagen={template?.imagenLibro} objectFit="contain" />
						}
					</Link>
				</div>
			</SectionBackground>
		</>
	);

}