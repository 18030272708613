import { css } from "@emotion/react"
import { ErrorMessage, Field, Form, Formik } from "formik"
import { navigate } from "gatsby"
import React, { useState } from "react"

export default function EstarCercaForm({ data }) {
  const [envio, setEnvio] = useState("")

  const formCss = css`
    max-width: calc(100vw * (calc(450 / var(--width_base))));
    margin-left: auto;
    margin-right: auto;

    & .form-label {
      font-size: calc(100vw * (calc(15 / var(--width_base))));
      line-height: calc(100vw * (calc(15 / var(--width_base))));
      font-style: italic;
      @media (max-width: 767px) {
        font-size: calc(100vw * (calc(12 / var(--width_base))));
        line-height: calc(100vw * (calc(16 / var(--width_base))));
      }
    }

    & .form-group {
      margin-bottom: calc(100vw * (calc(15 / var(--width_base))));
    }

    & .form-error {
      margin-bottom: calc(100vw * (calc(10 / var(--width_base))));
    }

    & .form-control {
      padding: calc(100vw * (calc(7 / var(--width_base))))
        calc(100vw * (calc(10 / var(--width_base))));
      font-size: calc(100vw * (calc(15 / var(--width_base))));
      line-height: calc(100vw * (calc(22 / var(--width_base))));
      border-radius: calc(100vw * (calc(6 / var(--width_base))));
      border: calc(100vw * (calc(2 / var(--width_base)))) dotted black;
      background-color: transparent;
      font-style: italic;

      @media (max-width: 767px) {
        font-size: calc(100vw * (calc(12 / var(--width_base))));
        line-height: calc(100vw * (calc(26 / var(--width_base))));
      }
    }
  `

  const submitCss = css`
    background-color: #096654 !important;
    padding: calc(100vw * (calc(10 / var(--width_base))))
      calc(100vw * (calc(10 / var(--width_base))));
    font-size: calc(100vw * (calc(16 / var(--width_base))));
    line-height: calc(100vw * (calc(22 / var(--width_base))));
    width: calc(100vw * (calc(180 / var(--width_base))));
    font-family: "Poppins SemiBold", sans-serif;
    font-weight: bold;
    display: block;
    margin-left: auto;
    margin-right: auto;
    color: white;
    text-align: center;
    margin-top: calc(100vw * (calc(20 / var(--width_base))));
    border-radius: calc(100vw * (calc(25 / var(--width_base))));
    @media (max-width: 767px) {
      margin-top: calc(100vw * (calc(5 / var(--width_base))));
      width: 100%;
      border-radius: calc(100vw * (calc(50 / var(--width_base))));
      padding: calc(100vw * (calc(9 / var(--width_base))))
        calc(100vw * (calc(10 / var(--width_base))));
      font-size: calc(100vw * (calc(12 / var(--width_base))));
      line-height: calc(100vw * (calc(16 / var(--width_base))));
    }
  `

  const formAceptCss = css`
    color: #0a0a0a;
    font-size: calc(100vw * (calc(16 / var(--width_base))));
    line-height: calc(100vw * (calc(20 / var(--width_base))));
    margin-top: calc(100vw * (calc(10 / var(--width_base))));
    display: inline-block;
    font-style: italic;
    @media (max-width: 767px) {
      margin-bottom: calc(100vw * (calc(15 / var(--width_base))));
      margin-left: calc(100vw * (calc(22 / var(--width_base))));
      font-size: calc(100vw * (calc(12 / var(--width_base))));
    }
    & input {
      @media (max-width: 767px) {
        margin-left: calc(100vw * (calc(-22 / var(--width_base))));
      }
    }
  `

  const linkCss = css`
    color: initial;
  `

  const {
    dondeIdEvolta,
    dondePaginaDeGracias: { uri: urlGracias },
  } = data

  return (
    <section>
      <Formik
        initialValues={{
          Nombre: "",
          Apellido: "",
          Correo: "",
          Celular: "",
          Direccion: "",
          // MeGustariaVivirEn: '',
          // QuieroUnDepaPara: "",
          // Dormitorios: "",
          Acepto_condiciones: "",
          Acepto_condiciones2: "",
        }}
        validate={values => {
          const errors = {}

          if (!values.Nombre) errors.Nombre = "Campo requerido"
          if (!values.Apellido) errors.Apellido = "Campo requerido"
          if (!values.Correo) errors.Correo = "Campo requerido"
          else if (
            !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.Correo)
          )
            errors.Correo = "Correo Invalido"
          if (!values.Celular) errors.Celular = "Campo requerido"
          // if (!values.QuieroUnDepaPara)
          //   errors.QuieroUnDepaPara = "Campo requerido"
          // if (!values.Dormitorios) errors.Dormitorios = "Campo requerido"
          // if (!values.Direccion) errors.Direccion = 'Campo requerido';
          // if (!values.MeGustariaVivirEn) errors.MeGustariaVivirEn = 'Campo requerido';
          if (!values.Acepto_condiciones) {
            errors.Acepto_condiciones = "Campo requerido"
            errors.Acepto_condiciones2 = "Campo requerido"
          }

          return errors
        }}
        onSubmit={(values, { setSubmitting, resetForm }) => {
          const dataJSON = sessionStorage.getItem("obj_data")
          const data = JSON.parse(dataJSON)

          const utm_contentModified = `${data?.utm_content}, Estar cerca Form`

          try {
            const jsonData = {
              email: values.Correo,
              fname: values.Nombre,
              lname: values.Apellido,
              phone: values.Celular,
              project_id: parseInt(dondeIdEvolta),
              interest_type_id: data?.interese_type_id,
              source_id: data?.source_id,
              observation: `Estar cerca Form`,
              input_channel_id: data?.input_channel_id,
              utm_source: data?.utm_source,
              utm_medium: data?.utm_medium,
              utm_campaign: data?.utm_campaign,
              utm_content: utm_contentModified,
              utm_term: data?.gclid,
              client_ip_address: data?.ip_address,
              fbc: data?.fbc,
              fbp: data?.fbp,
            }

            fetch(`${process.env.GATSBY_API_APROS_CREAR_CLIENTES}`, {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
              },
              body: JSON.stringify(jsonData),
            })
              .then(res => res.json())
              .then(json => {
                console.log("jsondata", jsonData)
                json = JSON.parse(json)
                if (json?.data?.id) {
                  setEnvio(true)
                  setSubmitting(false)
                  resetForm()
                  console.log("correo enviado")
                  if (urlGracias) navigate(urlGracias)

                  fetch(`${process.env.GATSBY_WEBHOOK}`, {
                    method: "POST",
                    headers: {
                      "Content-Type": "application/json",
                    },
                    body: JSON.stringify(jsonData),
                  })
                    .then(response => response.json())
                    .then(result => console.log(result?.data?.status))
                    .catch(error => console.error(error))
                } else {
                  setEnvio(false)
                }
              })
          } catch (error) {
            console.error(error)
            setEnvio(false)
          }
        }}
      >
        {({ isSubmitting }) => (
          <Form className="grid-100 grid-x-0" css={formCss}>
            <div className="grid-100 form-group grid-x-0">
              <label className="form-label" htmlFor="Nombre">
                Nombre:
              </label>
              <Field
                className="form-control"
                type="text"
                name="Nombre"
                id="Nombre"
              />
              <ErrorMessage
                className="form-error"
                name="Nombre"
                component="div"
              />
            </div>
            <div className="grid-100 form-group grid-x-0">
              <label className="form-label" htmlFor="Apellido">
                Apellido:
              </label>
              <Field
                className="form-control"
                type="text"
                name="Apellido"
                id="Apellido"
              />
              <ErrorMessage
                className="form-error"
                name="Apellido"
                component="div"
              />
            </div>
            <div className="grid-100 form-group grid-x-0">
              <label className="form-label" htmlFor="Correo">
                Correo:{" "}
              </label>
              <Field
                className="form-control"
                type="email"
                name="Correo"
                id="Correo"
              />
              <ErrorMessage
                className="form-error"
                name="Correo"
                component="div"
              />
            </div>
            <div className="grid-100 form-group grid-x-0">
              <label className="form-label" htmlFor="Celular">
                Celular:
              </label>
              <Field
                className="form-control"
                type="tel"
                name="Celular"
                id="Celular"
              />
              <ErrorMessage
                className="form-error"
                name="Celular"
                component="div"
              />
            </div>
            <div className="grid-100 form-group grid-x-0">
              <label
                className="form-acept"
                htmlFor="Acepto_condiciones"
                css={formAceptCss}
              >
                <Field
                  className="accept-box"
                  type="checkbox"
                  name="Acepto_condiciones"
                  id="Acepto_condiciones"
                />
                &nbsp;&nbsp;&nbsp;He leído y acepto la{" "}
                <a
                  href="/politica-de-privacidad/"
                  css={linkCss}
                  target="_blank"
                  rel="noreferrer"
                >
                  Política de privacidad
                </a>
              </label>
              <ErrorMessage
                className="form-error mov-mb-13"
                name="Acepto_condiciones"
                component="div"
              />
              <label
                className="form-acept"
                htmlFor="Acepto_condiciones2"
                css={formAceptCss}
              >
                <Field
                  className="accept-box"
                  type="checkbox"
                  name="Acepto_condiciones2"
                  id="Acepto_condiciones2"
                />
                &nbsp;&nbsp;&nbsp;He leído y acepto la transferencia de mis
                datos a las empresas del grupo empresarial Armando Paredes.
              </label>
              <ErrorMessage
                className="form-error mov-mb-13"
                name="Acepto_condiciones2"
                component="div"
              />
            </div>
            <div className="grid-100 grid-x-0">
              <button
                className=""
                type="submit"
                disabled={isSubmitting}
                css={submitCss}
              >
                Enviar
              </button>
            </div>
            {envio === false && envio !== "" && (
              <div className="">Error en el envio</div>
            )}
            {envio === true && envio !== "" && (
              <div className="">Correo enviado</div>
            )}
          </Form>
        )}
      </Formik>
    </section>
  )
}
