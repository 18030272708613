import React, {useState} from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { css } from "@emotion/react"

export default function MuchoGustoForm() {

  const [envio, setEnvio] = useState("");

  const formCss = css`
    
    max-width: calc(100vw * (calc(450 / var(--width_base))));
    margin-left: auto;
    margin-right: auto;
    
    & .form-label{
      font-size: calc(100vw * (calc(15 / var(--width_base))));
      line-height: calc(100vw * (calc(15 / var(--width_base))));
      font-style: italic;
      @media (max-width: 767px) {
        font-size: calc(100vw * (calc(12 / var(--width_base))));
        line-height: calc(100vw * (calc(16 / var(--width_base))));
      }
    }
    
    & .form-group{
      margin-bottom: calc(100vw * (calc(15 / var(--width_base)))); 
    }
    
    & .form-error{
      margin-bottom: calc(100vw * (calc(10 / var(--width_base))));
    }
    
    & .form-control{
      padding: calc(100vw * (calc(7 / var(--width_base)))) calc(100vw * (calc(10 / var(--width_base))));
      font-size: calc(100vw * (calc(15 / var(--width_base))));
      line-height: calc(100vw * (calc(22 / var(--width_base))));
      border-radius: calc(100vw * (calc(6 / var(--width_base))));
      border: calc(100vw * (calc(2 / var(--width_base)))) dotted black;
      background-color: transparent;
      font-style: italic;
      
      @media (max-width: 767px) {
        font-size: calc(100vw * (calc(12 / var(--width_base))));
        line-height: calc(100vw * (calc(26 / var(--width_base))));          
      }
    }
  `;

  const submitCss = css`
    background-color: #096654 !important;
    padding: 
      calc(100vw * (calc(10 / var(--width_base)))) 
      calc(100vw * (calc(10 / var(--width_base))));
    font-size: calc(100vw * (calc(16 / var(--width_base))));
    line-height: calc(100vw * (calc(22 / var(--width_base))));
    width: calc(100vw * (calc(180 / var(--width_base))));
    font-family: "Poppins SemiBold", sans-serif;
    font-weight: bold;
    display: block;
    margin-left: auto;
    margin-right: auto;
    color: white;
    text-align: center;
    margin-top: calc(100vw * (calc(20 / var(--width_base))));
    border-radius: calc(100vw * (calc(6 / var(--width_base))));
    @media (max-width: 767px) {
      margin-top: calc(100vw * (calc(5 / var(--width_base))));
      width: 100%;
      border-radius: calc(100vw * (calc(50 / var(--width_base))));
      padding: 
      calc(100vw * (calc(9 / var(--width_base)))) 
      calc(100vw * (calc(10 / var(--width_base))));
      font-size: calc(100vw * (calc(12 / var(--width_base))));
      line-height: calc(100vw * (calc(16 / var(--width_base))));
    }
  `;


  const formAceptCss = css`
    color: #0a0a0a;
    font-size: calc(100vw * (calc(16 / var(--width_base))));
    line-height: calc(100vw * (calc(20 / var(--width_base))));
    margin-top: calc(100vw * (calc(10 / var(--width_base))));
    display: inline-block;
    font-style: italic; 
    @media (max-width: 767px) {
      margin-bottom: calc(100vw * (calc(15 / var(--width_base))));
      margin-left: calc(100vw * (calc(22 / var(--width_base))));
      font-size: calc(100vw * (calc(12 / var(--width_base))));
    }
    & input{
      @media (max-width: 767px) {
        margin-left: calc(100vw * (calc(-22 / var(--width_base))));
      }
    }
  `;

  const linkCss = css`
    color: initial;
  `;

  return (
    <section>
      <Formik
        initialValues={{
          NombreyApellidos: '',
          Correo: '',
          Celular: '',
          Direccion: '',
          // MeGustariaVivirEn: '',
          QuieroUnDepaPara: '',
          Dormitorios: '',
          Acepto_condiciones: '',
          Acepto_condiciones2: '',
        }}
        validate={values => {
          const errors = {};

          if (!values.NombreyApellidos) errors.NombreyApellidos = 'Campo requerido';
          if (!values.Correo) errors.Correo = 'Campo requerido';
          else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.Correo)) errors.Correo = 'Correo Invalido';
          if (!values.Celular) errors.Celular = 'Campo requerido';
          if (!values.QuieroUnDepaPara) errors.QuieroUnDepaPara = 'Campo requerido';
          if (!values.Dormitorios) errors.Dormitorios = 'Campo requerido';
          // if (!values.Direccion) errors.Direccion = 'Campo requerido';
          // if (!values.MeGustariaVivirEn) errors.MeGustariaVivirEn = 'Campo requerido';
          if (!values.Acepto_condiciones) {
            errors.Acepto_condiciones = 'Campo requerido';
          }
          if (!values.Acepto_condiciones2) {
            errors.Acepto_condiciones2 = 'Campo requerido';
          }

          return errors;
        }}
        onSubmit={(values, { setSubmitting,resetForm }) => {
          try{

            const formData = new FormData();
            formData.append("_wpcf7_unit_tag", "wpcf7-f13918-o1")
            formData.append('NombresYApellidos', values.NombreyApellidos);
            formData.append('Correo', values.Correo);
            formData.append('Celular', values.Celular);
            formData.append('QuieroUnDepaPara', values.QuieroUnDepaPara);
            formData.append('Dormitorios', values.Dormitorios);

            fetch(`${process.env.GATSBY_PAGINA_COMPILADOR}/wp-json/contact-form-7/v1/contact-forms/13918/feedback`, {
              method: 'POST',
              body: formData
            })
              .then(res => res.json())
              .then(json => {
                if(json.status === "mail_sent"){
                  setEnvio(true)
                  setSubmitting(false);
                  resetForm();
                  console.log("correo enviado");
                }
                else{
                  setEnvio(false)
                }
              })
          }catch (error) {
            console.error(error);
            setEnvio(false)
          }
        }}
      >
        {({ isSubmitting }) => (
          <Form className="grid-100 grid-x-0" css={formCss}>
            <div className="grid-100 form-group grid-x-0">
              <label className="form-label" htmlFor="NombreyApellidos">Nombre y Apellidos:</label>
              <Field className="form-control" type="text" name="NombreyApellidos" id="NombreyApellidos"/>
              <ErrorMessage className="form-error" name="NombreyApellidos" component="div" />
            </div>
            <div className="grid-100 form-group grid-x-0">
              <label className="form-label" htmlFor="Correo">Correo: </label>
              <Field className="form-control" type="email" name="Correo" id="Correo"/>
              <ErrorMessage className="form-error" name="Correo" component="div" />
            </div>
            <div className="grid-100 form-group grid-x-0">
              <label className="form-label" htmlFor="Celular">Celular:</label>
              <Field className="form-control" type="tel" name="Celular" id="Celular"/>
              <ErrorMessage className="form-error" name="Celular" component="div" />
            </div>
            <div className="form-group">
              <label className="form-label" htmlFor="QuieroUnDepaPara">Quiero un depa para:</label>
              <Field name="QuieroUnDepaPara" as="select" className="form-control" id="QuieroUnDepaPara">
                <option value="">Seleccionar</option>
                <option value="Invertir">Invertir</option>
                <option value="Vivir">Vivir</option>
              </Field>
              <ErrorMessage className="form-error" name="QuieroUnDepaPara" component="div" />
            </div>
            <div className="form-group">
              <label className="form-label" htmlFor="Dormitorios">Dormitorios:</label>
              <Field name="Dormitorios" as="select" className="form-control" id="Dormitorios">
                <option value="">Seleccionar</option>
                <option value="1">1</option>
                <option value="2">2</option>
                <option value="3">3</option>
              </Field>
              <ErrorMessage className="form-error" name="Dormitorios" component="div" />
            </div>
            <div className="grid-100 form-group grid-x-0">
              <label className="form-acept" htmlFor="Acepto_condiciones" css={formAceptCss}>
                <Field className="accept-box" type="checkbox" name="Acepto_condiciones" id="Acepto_condiciones"/>&nbsp;&nbsp;&nbsp;He leído y acepto la {' '}  <a href="/politica-de-privacidad/" css={linkCss} target="_blank" rel="noreferrer">Política de privacidad</a>.</label>
              <ErrorMessage className="form-error mov-mb-13" name="Acepto_condiciones" component="div" />
              <label className="form-acept" htmlFor="Acepto_condiciones2" css={formAceptCss}>
                <Field className="accept-box" type="checkbox" name="Acepto_condiciones2" id="Acepto_condiciones2"/>&nbsp;&nbsp;&nbsp;He leído y acepto la transferencia de mis datos a las empresas del grupo empresarial Armando Paredes.</label>
              <ErrorMessage className="form-error mov-mb-13" name="Acepto_condiciones2" component="div" />
            </div>
            <div className="grid-100 grid-x-0">
              <button className="" type="submit" disabled={isSubmitting} css={submitCss}>Enviar</button>
            </div>
            { (envio === false && envio !== "") && <div className="">Error en el envio</div>}
            { (envio === true && envio !== "") && <div className="">Correo enviado</div>}
          </Form>
        )}
      </Formik>
    </section>
  );

}